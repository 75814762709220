<template>
    <head>
        <meta name="description" content="Buscador de productos con envío FULL en MercadoLibre">
        <meta name="keywords" content="melifull,buscador,productos,full,mercadolibre,filtrar,envio,argentina">
    </head>
    <ProductList/>
</template>

<script>
import ProductList from './components/ProductList.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap";


export default {
    name: 'App',
    components: {
        ProductList
    }
}


</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}


.loading-background {
    background-color: rgba(133, 121, 121, 0.5); /* Fondo semitransparente */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 1s ease; /* Transición de desvanecimiento */
}


.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    z-index: 9999; /* para asegurarnos de que el loader esté en la parte superior */

}


/* Creating the dots */
.loader span {
    height: 25px;
    width: 25px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #4e91fc;
    border: 2px solid rgba(255, 255, 255, 0.99); /* Agregar borde blanco de 1px */
    animation: loading 1s linear infinite;
}

/* Creating the loading animation*/
@keyframes loading {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(15px);
    }
    50% {
        transform: translateX(-15px);
    }
    100% {
        transform: translateX(0);
    }

}

span:nth-child(1) {
    animation-delay: 0.1s;
}

span:nth-child(2) {
    animation-delay: 0.2s;
}

span:nth-child(3) {
    animation-delay: 0.3s;
}

span:nth-child(4) {
    animation-delay: 0.4s;
}

span:nth-child(5) {
    animation-delay: 0.5s;
}

/* Aplicar el fondo gris solo cuando se muestra el loader */

main[role=main] {
    font-family: Proxima Nova, -apple-system, Roboto, Arial, sans-serif;
}

.ui-search-item__shipping--free {
    color: #00a650;
}

.ui-search-color--LIGHT_GREEN {
    color: #00a650;
}

.ui-search-item__group__element--shipping {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.andes-pagination {
    -webkit-font-smoothing: antialiased;
    font-family: Proxima Nova, -apple-system, Roboto, Arial, sans-serif;
    font-weight: 400;
    text-align: center;
}

.andes-pagination__arrow-title {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.andes-pagination__button {
    display: inline-block;
    margin-left: 4px;
}

.andes-pagination__link {
    background-color: transparent;
    border: 2px solid transparent;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .55);
    cursor: pointer;
    display: block;
    font-size: 16px;
    height: 48px;
    line-height: 1;
    padding: 14px 0 0;
    position: relative;
    text-decoration: none;
    -webkit-transition: background-color .25s ease;
    transition: background-color .25s ease;
    width: 48px;
}

.andes-pagination__link:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, .3);
    -moz-box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, .3);
    -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, .3);
    outline: none;
}

.andes-pagination__link:focus-visible {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, .3);
    -moz-box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, .3);
    -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, .3);
    outline: none;
}

.andes-pagination__link:focus:not(:focus-visible) {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
}

.andes-pagination__link[aria-current=page] {
    background-color: rgba(0, 0, 0, .04);
    color: rgba(0, 0, 0, .9);
    cursor: default;
    font-weight: 600;
    pointer-events: none;
}

.andes-pagination__link[aria-current=page]:active, .andes-pagination__link[aria-current=page]:link, .andes-pagination__link[aria-current=page]:visited {
    color: rgba(0, 0, 0, .9);
    text-decoration: none;
}

.andes-pagination__link:hover {
    background-color: rgba(0, 0, 0, .04);
    border: 2px solid transparent;
}

.andes-pagination__link:active {
    background-color: rgba(0, 0, 0, .07);
    -webkit-transition: background-color 50ms ease;
    transition: background-color 50ms ease;
}

.andes-pagination__link:active, .andes-pagination__link:link, .andes-pagination__link:visited {
    color: rgba(0, 0, 0, .55);
    text-decoration: none;
}

.andes-pagination__arrow {
    border-color: rgba(0, 0, 0, .55);
    border-style: solid;
    border-width: 1px 1px 0 0;
    display: inline-block;
    height: 7px;
    width: 7px;
}

.andes-pagination__button--back .andes-pagination__link, .andes-pagination__button--next .andes-pagination__link {
    width: 112px;
}

.andes-pagination__button--next .andes-pagination__link {
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
}

.andes-pagination__button--next .andes-pagination__arrow-title {
    margin-right: 4px;
}

.andes-pagination__button--next .andes-pagination__arrow {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.andes-pagination__button--back {
    margin-left: 0;
}

.andes-pagination__button--back .andes-pagination__link {
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
}

.andes-pagination__button--back .andes-pagination__arrow {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

.andes-pagination__button--back .andes-pagination__arrow-title {
    margin-left: 4px;
}

.andes-pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 8px 16px;
    padding: 0;
}

@media (min-width: 768px) {
    .andes-pagination {
        display: block;
        margin: 32px 0 0;
    }

    .andes-pagination__button .andes-pagination__link {
        height: 36px;
        padding: 9px 0 0;
        width: 36px;
    }

    .andes-pagination__button--back .andes-pagination__link, .andes-pagination__button--next .andes-pagination__link {
        padding: 9px 16px 0;
        width: 100%;
    }
}

@-webkit-keyframes modal-fade-in {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes modal-fade-out {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes modal-translate-in {
    0% {
        margin-top: 300px;
    }
    to {
        margin-top: 0;
    }
}

@-webkit-keyframes modal-translate-out {
    0% {
        margin-top: 0;
    }
    to {
        margin-top: 300px;
    }
}

@media (min-width: 768px) {

}

@-webkit-keyframes andes-pi-circular-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@-webkit-keyframes andes-pi-circular-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    to {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

@-webkit-keyframes andes-button-start-width-animation {
    0% {
        width: 0;
    }
    to {
        width: 90%;
    }
}

@keyframes andes-button-start-width-animation {
    0% {
        width: 0;
    }
    to {
        width: 90%;
    }
}

.andes-button svg {
    display: inline-block;
    max-height: 1.125em;
    max-width: 1.125em;
    vertical-align: middle;
}

.andes-button__content * {
    display: block;
    float: left;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes translateInButtonSpinner {
    0% {
        -webkit-transform: translate(-webkit-calc(50% - 20px), 50%);
        transform: translate(calc(50% - 20px), 50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px));
    }
}

@keyframes translateInButtonSpinner {
    0% {
        -webkit-transform: translate(-webkit-calc(50% - 20px), 50%);
        transform: translate(calc(50% - 20px), 50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px));
    }
}

@-webkit-keyframes translateOutButtonSpinner {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    to {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@keyframes translateOutButtonSpinner {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    to {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@-webkit-keyframes translateOutButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px));
    }
    to {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px);
    }
}

@keyframes translateOutButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px));
    }
    to {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px);
    }
}

@-webkit-keyframes translateInButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 22px));
        transform: translateY(calc(-50% + 22px));
    }
}

@keyframes translateInButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 22px));
        transform: translateY(calc(-50% + 22px));
    }
}

@-webkit-keyframes translateInButtonSpinnerCompleteMedium {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 13px));
        transform: translateY(calc(-50% + 13px));
    }
}

@keyframes translateInButtonSpinnerCompleteMedium {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 13px));
        transform: translateY(calc(-50% + 13px));
    }
}

@-webkit-keyframes translateInButtonSpinnerCompleteSmall {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 10px));
        transform: translateY(calc(-50% + 10px));
    }
}

@keyframes translateInButtonSpinnerCompleteSmall {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 10px));
        transform: translateY(calc(-50% + 10px));
    }
}

.andes-button--loud:hover path {
    stroke: #ffffff !important;
}

@media (min-width: 768px) {

    .andes-button--loud:hover path {
        stroke: #ffffff !important;
    }
}

.andes-button--loud path, .andes-button--loud:focus path, .andes-button--loud:link path, .andes-button--loud:visited path {
    stroke: #ffffff !important;
}

.andes-button--loud:active path {
    stroke: #ffffff !important;
}

.andes-button--loud:disabled path, .andes-button--loud[disabled] path {
    stroke: rgba(0, 0, 0, .25) !important;
}

.andes-button--quiet:hover path {
    stroke: #3483fa !important;
}

@media (min-width: 768px) {

    .andes-button--quiet:hover path {
        stroke: #3483fa !important;
    }
}

.andes-button--quiet path, .andes-button--quiet:focus path, .andes-button--quiet:link path, .andes-button--quiet:visited path {
    stroke: #3483fa !important;
}

.andes-button--quiet:active path {
    stroke: #3483fa !important;
}

.andes-button--quiet:disabled path, .andes-button--quiet[disabled] path {
    stroke: rgba(0, 0, 0, .25) !important;
}

.andes-button--transparent:hover path {
    stroke: #3483fa !important;
}

@media (min-width: 768px) {

    .andes-button--transparent:hover path {
        stroke: #3483fa !important;
    }
}

.andes-button--transparent path, .andes-button--transparent:focus path, .andes-button--transparent:link path, .andes-button--transparent:visited path {
    stroke: #3483fa !important;
}

.andes-button--transparent:active path {
    stroke: #3483fa !important;
}

.andes-button--transparent:disabled path, .andes-button--transparent[disabled] path {
    stroke: rgba(0, 0, 0, .25) !important;
}

.andes-button--quiet.loading:active path, .andes-button--quiet.loading:disabled path, .andes-button--quiet.loading:hover path {
    stroke: #3483fa !important;
}

.andes-button--quiet:after path, .andes-button--quiet:before path {
    stroke: #3483fa !important;
}

.andes-button--loud.loading:active path, .andes-button--loud.loading:disabled path, .andes-button--loud.loading:hover path {
    stroke: #ffffff !important;
}

.andes-button--loud:after path, .andes-button--loud:before path {
    stroke: #ffffff !important;
}

.andes-button.andes-button--fab .andes-button--icon-container img, .andes-button.andes-button--fab .andes-button--icon-container svg {
    display: inline-block;
    height: 20px;
    max-height: inherit;
    max-width: inherit;
    width: 20px;
}

.andes-button.andes-button--fab.andes-button--small .andes-button--icon-container img, .andes-button.andes-button--fab.andes-button--small .andes-button--icon-container svg {
    display: inline-block;
    max-height: inherit;
    max-width: inherit;
}

@-webkit-keyframes andes-snackbar-show {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
    }
}

@keyframes andes-snackbar-show {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes andes-snackbar-hide {
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes andes-snackbar-hide {
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@media (min-width: 768px) {

}

.price-tag {
    display: inline-block;
    line-height: 1em;
    overflow: visible;
    vertical-align: text-bottom;
}

.price-tag__disabled {
    position: relative;
    text-decoration: none;
}

.price {
    position: relative;
    background-color: rgba(255, 0, 0, 0.05);
    font-size: 2rem;
}

.price__holder {
    position: relative;
    background-color: rgba(255, 255, 0, 0.2);
}

.price__value {
    background-color: rgba(255, 0, 255, 0.1);
}

.price__decimals {
    position: absolute;
}

.price__decimals-value {
    position: absolute;
    left: 0;
    background-color: rgba(0, 255, 0, 0.1);
}

.price--with-small-decimals-bottom-aligned .price__decimals-value {
    margin-top: 0.25em;
    line-height: 1.3333333333;
    font-size: 0.75em;
}

.price::after {
    content: "00";
    visibility: hidden;
}

.price__currency {
    margin-right: 0.5em;
}

.price-tag__disabled .price-tag-amount:after {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 48%;
    width: 100%;
}

.price-tag * {
    float: left;
}

.price-tag-symbol {
    margin-right: .1em;
}

.price-tag-text-sr-only {
    clip: rect(0 0 0 0);
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    margin: 0 -1px -1px 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.price-tag-cents {
    font-size: .7em;
    line-height: 1em;
    margin-left: .05em;
    vertical-align: text-bottom;
}

.price-tag.price-tag-billing .price-tag-cents {
    font-size: inherit;
    line-height: inherit;
    margin-left: 0;
    vertical-align: baseline;
}

@-webkit-keyframes animation-indeterminate {
    0% {
        left: 0;
        width: 5%;
    }
    50% {
        left: 15%;
        width: 60%;
    }
    to {
        left: 101%;
        width: 0;
    }
}


@-webkit-keyframes show-valid-ckeck {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes show-valid-ckeck {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes andes-pi-circular-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes andes-pi-circular-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    to {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.andes-form-control__field.andes-form-control__split-field .andes-form-control__control input {
    padding: 0;
}

.andes-form-control--disabled.andes-form-control__split-button .andes-floating-menu .andes-dropdown__trigger .andes-dropdown__standalone-arrow path {
    fill: rgba(0, 0, 0, .25);
}

.andes-modal > * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .9);
}

@keyframes modal-fade-in {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes modal-fade-out {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes modal-translate-in {
    0% {
        margin-top: 300px;
    }
    to {
        margin-top: 0;
    }
}

@keyframes modal-translate-out {
    0% {
        margin-top: 0;
    }
    to {
        margin-top: 300px;
    }
}

@-webkit-keyframes rotate-left {
    to {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
    0% {
        -webkit-transform: rotate(175deg);
        transform: rotate(175deg);
    }
}

@-webkit-keyframes rotate-right {
    0% {
        -webkit-transform: rotate(-175deg);
        transform: rotate(-175deg);
    }
    to {
        -webkit-transform: rotate(-30deg);
        transform: rotate(-30deg);
    }
}

@-webkit-keyframes rotate-all {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.andes-input-stepper__button--decrement svg, .andes-input-stepper__button--increment svg {
    max-height: unset !important;
    max-width: unset !important;
}

.andes-input-stepper__button--decrement svg path, .andes-input-stepper__button--increment svg path {
    fill: #3483fa;
}

.andes-input-stepper__button.andes-button--disabled.andes-input-stepper__button--decrement svg path, .andes-input-stepper__button.andes-button--disabled.andes-input-stepper__button--increment svg path {
    fill: rgba(0, 0, 0, .25);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes translateInLabel {
    0% {
        -webkit-transform: translateY(32px);
        transform: translateY(32px);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes translateInLabel {
    0% {
        -webkit-transform: translateY(32px);
        transform: translateY(32px);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes translateOutLabel {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(32px);
        transform: translateY(32px);
    }
}

@keyframes translateOutLabel {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(32px);
        transform: translateY(32px);
    }
}

@-webkit-keyframes translateInProgressIndicator {
    0% {
        -webkit-transform: translateY(-36px);
        transform: translateY(-36px);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes translateInProgressIndicator {
    0% {
        -webkit-transform: translateY(-36px);
        transform: translateY(-36px);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes translateOutProgressIndicator {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(-36px);
        transform: translateY(-36px);
    }
}

@keyframes translateOutProgressIndicator {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(-36px);
        transform: translateY(-36px);
    }
}

.andes-progress-indicator-linear .fill {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    height: 100%;
    position: absolute;
}

.slick-slider {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -khtml-user-select: none;
}

.slick-list, .slick-slider {
    display: block;
    position: relative;
}

.slick-list {
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-slider .slick-list, .slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.slick-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;
}

.slick-track:after, .slick-track:before {
    content: "";
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto;
}

.slick-dots li {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    position: relative;
}

.slick-dots li, .slick-dots li button {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.slick-dots li button {
    background: transparent;
    border: 0;
    color: transparent;
    display: block;
    font-size: 0;
    line-height: 0;
    outline: none;
    padding: 5px;
}

.slick-dots li button:focus, .slick-dots li button:hover {
    outline: none;
}

.slick-dots li button:focus:before, .slick-dots li button:hover:before {
    opacity: 1;
}

.slick-dots li button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    content: "•";
    font-family: slick;
    font-size: 6px;
    height: 20px;
    left: 0;
    line-height: 20px;
    opacity: .25;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20px;
}

.slick-dots li.slick-active button:before {
    color: #000000;
    opacity: .75;
}

.carousel-container {
    position: relative;
}

.slick-slider {
    position: static;
}

.slick-slider .slick-slide {
    position: relative;
}

@media screen and (max-width: 1168px) {
}

@media screen and (max-width: 1168px)and (max-width: 769px) {
}

@media screen and (max-width: 1168px) {
}

@media screen and (max-width: 1168px)and (max-width: 769px) {
}

.mshops-pads-variation-original__cta a {
    color: #3483fa !important;
    font-weight: 500;
}

.mshops-pads-variation-original__cta a:first-child {
    border-right: 1px solid rgba(0, 0, 0, .1);
    margin-right: 8px;
    padding-right: 8px;
}

@supports (-webkit-line-clamp:2) {
}

@media (max-width: 766px) {
}

@media (max-width: 766px) {
}

figure {
    margin: 0;
}

@media (min-width: 768px) {
}

@media (min-width: 800px) {
}

@media (max-width: 820px) {

}

.ui-search-icon--chevron svg path {
    stroke-width: 1.5;
}

@media (max-width: 1220px)and (min-width: 1120px) {
}

@media (max-width: 1119px) {
}

.ui-search-filter-official-store__tooltip a {
    color: #595959;
    font-size: 12px;
    line-height: 12px;
}


.ui-search-filter-highlighted-coin_highlighted form > button > label {
    -webkit-align-items: center;
    align-items: center;
    background-color: #fff1cb;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #a47b2e;
    display: -webkit-flex;
    display: flex;
    height: 20px;
    -webkit-justify-content: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
}

.ui-search-filter-highlighted-coin_highlighted form > button > label svg {
    margin-left: 4px;
}

.ui-search-filter-highlighted .ui-search-item__highlight-label {
    font-size: 14px;
}

.ui-search-filter-highlighted__content .ui-search-item__highlight-label {
    color: rgba(0, 0, 0, .8);
}

.ui-search-filter-highlighted__content-button label {
    cursor: pointer;
}

@media (max-width: 1024px) {
}

.ui-search-filter-highlighted-WITH_VIRTUAL_TOUR_HIGHLIGHTED .ui-search-filter-highlighted__title > svg {
    margin-right: 8px;
}

.ui-search-filter-highlighted-RENTAL_TYPE_HIGHLIGHTED .ui-search-item__highlight-label--no-background {
    font-weight: 600;
}

@-webkit-keyframes switchOn {
    0% {
        left: 0;
    }
    to {
        left: 20px;
    }
}

@keyframes switchOn {
    0% {
        left: 0;
    }
    to {
        left: 20px;
    }
}

@-webkit-keyframes switchOff {
    0% {
        left: 20px;
    }
    to {
        left: 0;
    }
}

@keyframes switchOff {
    0% {
        left: 20px;
    }
    to {
        left: 0;
    }
}

@-webkit-keyframes switchOnSmall {
    0% {
        left: 0;
    }
    to {
        left: 16px;
    }
}

@keyframes switchOnSmall {
    0% {
        left: 0;
    }
    to {
        left: 16px;
    }
}

@-webkit-keyframes switchOffSmall {
    0% {
        left: 16px;
    }
    to {
        left: 0;
    }
}

@keyframes switchOffSmall {
    0% {
        left: 16px;
    }
    to {
        left: 0;
    }
}

.ui-search-filter-dd-size :hover {
    border-color: #262626;
    color: #262626;
    font-weight: 500;
}

.ui-search-price-filter-action-btn svg {
    -webkit-filter: invert(100%) sepia(0) saturate(0) hue-rotate(39deg) brightness(106%) contrast(101%);
    filter: invert(100%) sepia(0) saturate(0) hue-rotate(39deg) brightness(106%) contrast(101%);
    margin-left: 2px;
}

.ui-search-layout--gallery .ui-search-layout__item, .ui-search-layout--grid .ui-search-layout__item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis: 284px;
    flex-basis: 284px;
    -webkit-flex: 1 1;
    flex: 1 1;
    margin-bottom: 16px;
    margin-left: 16px;
    max-width: 284px;
    min-width: 284px;
    width: 100%;
}

@media (max-width: 1164px)and (min-width: 1024px),(max-width: 861px)and (min-width: 720px) {
    .ui-search-layout--gallery .ui-search-layout__item, .ui-search-layout--grid .ui-search-layout__item {
        -webkit-flex-basis: 240px;
        flex-basis: 240px;
        margin-bottom: 12px;
        margin-left: 12px;
        max-width: 240px;
        min-width: 240px;
    }
}

.ui-search-layout--gallery .ui-search-layout__item:first-child, .ui-search-layout--grid .ui-search-layout__item:first-child {
    margin-left: 0;
}

@media (max-width: 1023px)and (min-width: 862px) {
    .ui-search-layout--gallery .ui-search-layout__item:nth-child(3n+1), .ui-search-layout--grid .ui-search-layout__item:nth-child(3n+1) {
        margin-left: 16px;
    }

    .ui-search-layout--gallery .ui-search-layout__item:nth-child(odd), .ui-search-layout--grid .ui-search-layout__item:nth-child(odd) {
        margin-left: 0;
    }
}

@media (max-width: 861px)and (min-width: 720px) {
    .ui-search-layout--gallery .ui-search-layout__item:nth-child(3n+1), .ui-search-layout--grid .ui-search-layout__item:nth-child(3n+1) {
        margin-left: 12px;
    }

    .ui-search-layout--gallery .ui-search-layout__item:nth-child(odd), .ui-search-layout--grid .ui-search-layout__item:nth-child(odd) {
        margin-left: 0;
    }
}

@media (max-width: 719px) {
    .ui-search-layout--gallery .ui-search-layout__item, .ui-search-layout--grid .ui-search-layout__item {
        margin-left: 0;
    }
}

.ui-search-layout--gallery .ui-search-result__wrapper, .ui-search-layout--grid .ui-search-result__wrapper {
    display: block;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;

    width: 100%;
    z-index: 0;
}

.ui-search-layout--gallery .ui-search-result, .ui-search-layout--grid .ui-search-result {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    min-height: 100%;
    position: relative;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result:hover, .ui-search-layout--grid .ui-search-result:hover {
    -webkit-box-shadow: 0 25px 20px 0 rgba(0, 0, 0, .16), 0 -1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 25px 20px 0 rgba(0, 0, 0, .16), 0 -1px 2px 0 rgba(0, 0, 0, .1);
}

.ui-search-layout--gallery .ui-search-result__image, .ui-search-layout--grid .ui-search-result__image {
    border-bottom: 1px solid #eaeaea;
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    font-size: 1px;
    margin: 0;
    max-height: unset;
    position: relative;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result__image .ui-search-result-image__element, .ui-search-layout--grid .ui-search-result__image .ui-search-result-image__element {
    height: 100%;
    max-height: 284px;
    min-height: 284px;
}

@media (max-width: 1164px)and (min-width: 1024px),(max-width: 861px)and (min-width: 720px) {
    .ui-search-layout--gallery .ui-search-result__image .ui-search-result-image__element, .ui-search-layout--grid .ui-search-result__image .ui-search-result-image__element {
        max-height: 240px;
        min-height: 240px;
    }
}

.ui-search-layout--gallery .ui-search-result__image:after, .ui-search-layout--grid .ui-search-result__image:after {
    -webkit-animation: animation-indeterminate 2.5s linear infinite both;
    animation: animation-indeterminate 2.5s linear infinite both;
    -webkit-animation-name: none;
    animation-name: none;
    background-color: #999999;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 0;
    z-index: 2;
}

.ui-search-layout--gallery .ui-search-result__image .carousel-container, .ui-search-layout--grid .ui-search-result__image .carousel-container {
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
}

.ui-search-layout--gallery .ui-search-result__image .carousel-container, .ui-search-layout--gallery .ui-search-result__image .slick-list, .ui-search-layout--gallery .ui-search-result__image .slick-slide, .ui-search-layout--gallery .ui-search-result__image .slick-track, .ui-search-layout--grid .ui-search-result__image .carousel-container, .ui-search-layout--grid .ui-search-result__image .slick-list, .ui-search-layout--grid .ui-search-result__image .slick-slide, .ui-search-layout--grid .ui-search-result__image .slick-track {
    height: 100%;
    position: relative;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result__image .slick-slider, .ui-search-layout--grid .ui-search-result__image .slick-slider {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    max-height: 100%;
    position: relative;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result__image .slick-slider .slick-slide, .ui-search-layout--grid .ui-search-result__image .slick-slider .slick-slide {
    height: 100%;
    max-height: 284px;
    min-height: 284px;
}

@media (max-width: 1164px)and (min-width: 1024px),(max-width: 861px)and (min-width: 720px) {
    .ui-search-layout--gallery .ui-search-result__image .slick-slider .slick-slide, .ui-search-layout--grid .ui-search-result__image .slick-slider .slick-slide {
        max-height: 240px;
        min-height: 240px;
    }
}

.ui-search-layout--gallery .ui-search-result-image__element, .ui-search-layout--grid .ui-search-result-image__element {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result__content :last-child, .ui-search-layout--grid .ui-search-result__content :last-child {
    margin-bottom: 0;
}

.ui-search-layout--gallery .ui-search-result__content-wrapper, .ui-search-layout--grid .ui-search-result__content-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    padding: 22px 20px;
    position: relative;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__group, .ui-search-layout--grid .ui-search-result .ui-search-item__group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__group:last-child, .ui-search-layout--grid .ui-search-result .ui-search-item__group:last-child {
    margin-bottom: 0;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__group--price, .ui-search-layout--grid .ui-search-result .ui-search-item__group--price {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin-bottom: 13px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price, .ui-search-layout--grid .ui-search-result .ui-search-price {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price:after, .ui-search-layout--gallery .ui-search-result .ui-search-price:before, .ui-search-layout--grid .ui-search-result .ui-search-price:after, .ui-search-layout--grid .ui-search-result .ui-search-price:before {
    content: unset;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__part, .ui-search-layout--grid .ui-search-result .ui-search-price__part {
    color: #333333;
    display: -webkit-flex;
    display: flex;
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-right: 8px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__part .price-tag-symbol, .ui-search-layout--grid .ui-search-result .ui-search-price__part .price-tag-symbol {
    margin-right: 6px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__original-value, .ui-search-layout--grid .ui-search-result .ui-search-price__original-value {
    -webkit-align-items: center;
    align-items: center;
    color: #999999;
    font-size: 12px;
    height: 8px;
    margin-bottom: 5px;
    margin-top: 4px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__original-value .price-tag-symbol, .ui-search-layout--grid .ui-search-result .ui-search-price__original-value .price-tag-symbol {
    margin-left: 2px;
    margin-right: 4px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__second-line, .ui-search-layout--grid .ui-search-result .ui-search-price__second-line {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__second-line .price-tag-cents, .ui-search-layout--grid .ui-search-result .ui-search-price__second-line .price-tag-cents {
    line-height: 1.4em;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__second-line--decimal .price-tag-cents, .ui-search-layout--grid .ui-search-result .ui-search-price__second-line--decimal .price-tag-cents {
    line-height: inherit;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__second-line__label, .ui-search-layout--grid .ui-search-result .ui-search-price__second-line__label {
    display: -webkit-flex;
    display: flex;
    line-height: 1;
}

.ui-search-layout--gallery .ui-search-result .ui-search-price__discount, .ui-search-layout--grid .ui-search-result .ui-search-price__discount {
    color: #00a650;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
}

.ui-search-layout--gallery .ui-search-result .ui-search-installments, .ui-search-layout--grid .ui-search-result .ui-search-installments {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
}

.ui-search-layout--gallery .ui-search-result .ui-search-installments .ui-search-price__second-line, .ui-search-layout--grid .ui-search-result .ui-search-installments .ui-search-price__second-line {
    margin: 0 0 0 3px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-installments .ui-search-price__part, .ui-search-layout--grid .ui-search-result .ui-search-installments .ui-search-price__part {
    color: inherit;
    display: -webkit-flex;
    display: flex;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.25;
    margin: 0 .3rem;
}

.ui-search-layout--gallery .ui-search-result .ui-search-installments .ui-search-price__part .price-tag-symbol, .ui-search-layout--grid .ui-search-result .ui-search-installments .ui-search-price__part .price-tag-symbol {
    margin-right: .3rem;
}

.ui-search-layout--gallery .ui-search-result .ui-search-installments-prefix .ui-search-price__part, .ui-search-layout--grid .ui-search-result .ui-search-installments-prefix .ui-search-price__part {
    margin-left: 0;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__group--shipping, .ui-search-layout--grid .ui-search-result .ui-search-item__group--shipping {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    display: block;
    margin-bottom: 12px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__shipping, .ui-search-layout--grid .ui-search-result .ui-search-item__shipping {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-right: 5px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__shipping--free, .ui-search-layout--grid .ui-search-result .ui-search-item__shipping--free {
    font-weight: 600;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__group--title, .ui-search-layout--grid .ui-search-result .ui-search-item__group--title {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-bottom: 12px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__title, .ui-search-layout--grid .ui-search-result .ui-search-item__title {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    max-height: 36px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-result__bookmark, .ui-search-layout--grid .ui-search-result .ui-search-result__bookmark {
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 16px;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
}

.ui-search-layout--gallery .ui-search-result .ui-search-bookmark, .ui-search-layout--grid .ui-search-result .ui-search-bookmark {
    height: 36px;
    opacity: 0;
    position: relative;
    width: 36px;
    z-index: 1;
}

.ui-search-layout--gallery .ui-search-result .ui-search-bookmark__btn, .ui-search-layout--grid .ui-search-result .ui-search-bookmark__btn {
    -webkit-align-items: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    border: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #3483fa;
    content: "";
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
}

.ui-search-layout--gallery .ui-search-result .ui-search-bookmark__icon-bookmark, .ui-search-layout--gallery .ui-search-result .ui-search-bookmark__icon-bookmark-fill, .ui-search-layout--grid .ui-search-result .ui-search-bookmark__icon-bookmark, .ui-search-layout--grid .ui-search-result .ui-search-bookmark__icon-bookmark-fill {
    height: 18px;
    margin: 0;
    position: absolute;
    top: unset;
    width: 18px;
    z-index: 2;
}

.ui-search-layout--gallery .ui-search-result .ui-search-bookmark__icon-bookmark, .ui-search-layout--grid .ui-search-result .ui-search-bookmark__icon-bookmark {
    fill: transparent;
    stroke: currentColor;
    stroke-width: 2px;
}

.ui-search-layout--gallery .ui-search-result .ui-search-bookmark__icon-bookmark-fill, .ui-search-layout--grid .ui-search-result .ui-search-bookmark__icon-bookmark-fill {
    fill: currentColor;
    stroke: transparent;
    opacity: 0;
}

.ui-search-layout--gallery .ui-search-result .ui-search-item__highlight-label, .ui-search-layout--grid .ui-search-result .ui-search-item__highlight-label {
    margin-bottom: 16px;
    margin-top: 4px;
}

.ui-search-layout--gallery .ui-search-item__group--vertical-highlight .ui-search-item__group__element:last-child, .ui-search-layout--grid .ui-search-item__group--vertical-highlight .ui-search-item__group__element:last-child {
    margin-bottom: 0;
}

.ui-search-layout--gallery .ui-search-layout__item:hover, .ui-search-layout--grid .ui-search-layout__item:hover {
    z-index: 2;
}

.ui-search-layout--gallery .ui-search-layout__item:hover .ui-search-bookmark, .ui-search-layout--grid .ui-search-layout__item:hover .ui-search-bookmark {
    opacity: 1;
    pointer-events: auto;
}

.ui-search-layout--gallery .ui-search-bookmark__btn:hover, .ui-search-layout--grid .ui-search-bookmark__btn:hover {
    color: #1259c3;
}

.ui-search-layout--gallery .ui-search-result--core .ui-search-result__content-wrapper, .ui-search-layout--grid .ui-search-result--core .ui-search-result__content-wrapper {
    padding: 20px;
}

.ui-search-layout--gallery .ui-search-result--core .ui-search-installments .ui-search-price__second-line, .ui-search-layout--grid .ui-search-result--core .ui-search-installments .ui-search-price__second-line {
    margin: 0;
}

.ui-search-layout--gallery .ui-search-result--mot .ui-search-result__content-wrapper, .ui-search-layout--grid .ui-search-result--mot .ui-search-result__content-wrapper {
    overflow: hidden;
    padding: 24px;
}


.ui-search-layout--gallery .ui-search-result--mot .ui-search-item__group--price, .ui-search-layout--grid .ui-search-result--mot .ui-search-item__group--price {
    margin-bottom: 18px;
    margin-top: 2px;
}

.ui-search-layout--gallery .ui-search-result--mot .ui-search-item__group--title, .ui-search-layout--grid .ui-search-result--mot .ui-search-item__group--title {
    margin-top: -1px;
}

.ui-search-layout--gallery .ui-search-result--mot .ui-search-item__title, .ui-search-layout--grid .ui-search-result--mot .ui-search-item__title {
    max-width: 100%;
}

.ui-search-layout--gallery .ui-search-result--res .ui-search-result__content-wrapper, .ui-search-layout--grid .ui-search-result--res .ui-search-result__content-wrapper {
    overflow: hidden;
    padding: 20px 20px 19px;
}

.ui-search-layout--gallery .ui-search-result--res .ui-search-result__image .ui-search-result-image__element, .ui-search-layout--grid .ui-search-result--res .ui-search-result__image .ui-search-result-image__element {
    -o-object-fit: cover;
    object-fit: cover;
}

.ui-search-layout--gallery .ui-search-result--res .ui-search-item__group--price, .ui-search-layout--grid .ui-search-result--res .ui-search-item__group--price {
    margin-bottom: 18px;
}

.ui-search-layout--gallery .ui-search-result--res .ui-search-item__group--title, .ui-search-layout--grid .ui-search-result--res .ui-search-item__group--title {
    margin-bottom: 16px;
}

.ui-search-layout--gallery .ui-search-result--res .ui-search-item__title, .ui-search-layout--grid .ui-search-result--res .ui-search-item__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: unset;
}

.ui-search-layout--gallery .ui-search-result--srv .ui-search-result__content-wrapper, .ui-search-layout--grid .ui-search-result--srv .ui-search-result__content-wrapper {
    overflow: hidden;
    padding: 14px 20px 20px;
}

.ui-search-layout--gallery .ui-search-result--srv .ui-search-result__image, .ui-search-layout--grid .ui-search-result--srv .ui-search-result__image {

}

@media (max-width: 1164px)and (min-width: 1024px),(max-width: 861px)and (min-width: 720px) {
    .ui-search-layout--gallery .ui-search-result--srv .ui-search-result__image, .ui-search-layout--grid .ui-search-result--srv .ui-search-result__image {

    }
}

.ui-search-layout--gallery .ui-search-result--srv .ui-search-result__image .ui-search-result-image__element, .ui-search-layout--grid .ui-search-result--srv .ui-search-result__image .ui-search-result-image__element {

    -o-object-fit: cover;
    object-fit: cover;
}

@media (max-width: 1164px)and (min-width: 1024px),(max-width: 861px)and (min-width: 720px) {
    .ui-search-layout--gallery .ui-search-result--srv .ui-search-result__image .ui-search-result-image__element, .ui-search-layout--grid .ui-search-result--srv .ui-search-result__image .ui-search-result-image__element {

    }
}

.ui-search-layout--gallery .ui-search-result--srv .ui-search-item__group--title, .ui-search-layout--grid .ui-search-result--srv .ui-search-item__group--title {
    margin-bottom: 20px;
}

.ui-search-layout--gallery .ui-search-result--srv .ui-search-item__title, .ui-search-layout--grid .ui-search-result--srv .ui-search-item__title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 20px;
    font-weight: 500;
    height: 100%;
    line-height: 1.25;
    margin: 0;
    max-height: 52px;
}

.ui-search-layout--gallery .ui-search-result--srv .ui-search-item__group--price, .ui-search-layout--grid .ui-search-result--srv .ui-search-item__group--price {
    margin-top: -2px;
}

.ui-search-layout--gallery .ui-search-result--cpg .ui-search-result-image__element, .ui-search-layout--grid .ui-search-result--cpg .ui-search-result-image__element {
    min-height: 284px;
}

.ui-search-layout--gallery .ui-search-result--cpg .ui-search-result__content-wrapper, .ui-search-layout--grid .ui-search-result--cpg .ui-search-result__content-wrapper {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding: 20px;
}

.ui-search-layout--gallery .ui-search-result--cpg .ui-search-installments .ui-search-price__second-line, .ui-search-layout--grid .ui-search-result--cpg .ui-search-installments .ui-search-price__second-line {
    margin: 0;
}

@keyframes animation-indeterminate {
    0% {
        left: 0;
        width: 5%;
    }
    50% {
        left: 15%;
        width: 50%;
    }
    to {
        left: 100%;
        width: 0;
    }
}

.ui-search-results--without-disclaimer .ui-search-layout--stack .ui-search-layout__item:last-child .ui-search-result {
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.ui-search-layout--stack {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.ui-search-layout--stack .ui-search-layout__item {
    border-bottom: thin solid #eeeeee;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    width: 100%;
}

.ui-search-layout--stack .ui-search-layout__item:first-child .ui-search-result {
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.ui-search-layout--stack .ui-search-result__wrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.ui-search-layout--stack .andes-card--flat {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ui-search-layout--stack .ui-search-result {
    -webkit-border-radius: 0;
    border-radius: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding: 20px 30px 5px 0;
    position: relative;
    width: 100%;
}

.ui-search-layout--stack .ui-search-result__image {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    font-size: 1px;
    height: 160px;
    margin: 0;
    overflow: hidden;
    padding: 0 24px;
    width: 160px;
}

.ui-search-layout--stack .ui-search-result__image .ui-search-result-image__element {
    -o-object-fit: cover;
    object-fit: cover;
}

.ui-search-layout--stack .ui-search-result .carousel-container, .ui-search-layout--stack .ui-search-result .slick-list, .ui-search-layout--stack .ui-search-result .slick-slide, .ui-search-layout--stack .ui-search-result .slick-track {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    height: 100%;
    position: relative;
    width: 100%;
}

.ui-search-layout--stack .ui-search-result .slick-slider {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
}

.ui-search-layout--stack .ui-search-result-image__element {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
}

.ui-search-layout--stack .ui-search-result__content :last-child {
    margin-bottom: 0;
}

.ui-search-layout--stack .ui-search-result__content-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: -webkit-calc(100% - 208px);
    width: calc(100% - 208px);
}

.ui-search-layout--stack .ui-search-result__content-columns {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-order: 4;
    order: 4;
    position: relative;
    width: 100%;
}

.ui-search-layout--stack .ui-search-result__content-column {
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.ui-search-layout--stack .ui-search-result__content-column--left {
    -webkit-order: 1;
    order: 1;
    width: 45%;
}

.ui-search-layout--stack .ui-search-result__content-column--right {
    -webkit-order: 2;
    order: 2;
    position: relative;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__group--price {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin-bottom: 18px;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__group--price .ui-search-item__group__element {
    cursor: pointer;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__group--price .ui-search-price__part-without-link, .ui-search-layout--stack .ui-search-result .ui-search-item__group--price .ui-search-price__part-without-link + span {
    cursor: default;
}

.ui-search-layout--stack .ui-search-result .ui-search-price {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__part {
    color: #333333;
    display: -webkit-flex;
    display: flex;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.25;
    margin-right: 8px;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__part .price-tag-symbol {
    margin-right: 6px;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__original-value {
    -webkit-align-items: center;
    align-items: center;
    color: #999999;
    font-size: 12px;
    margin-top: 4px;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__original-value .price-tag-symbol {
    margin-right: 2px;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__second-line {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__second-line .price-tag-cents {
    line-height: 1.8em;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__second-line--decimal .price-tag-billing .price-tag-cents {
    line-height: inherit;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__second-line__label {
    display: -webkit-flex;
    display: flex;
    line-height: 1;
}

.ui-search-layout--stack .ui-search-result .ui-search-price__discount {
    color: #00a650;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
}

.ui-search-layout--stack .ui-search-result .ui-search-installments {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
}

.ui-search-layout--stack .ui-search-result .ui-search-installments .ui-search-price__second-line {
    margin: 0;
}

.ui-search-layout--stack .ui-search-result .ui-search-installments .ui-search-price__second-line .price-tag-cents {
    line-height: 1.2em;
}

.ui-search-layout--stack .ui-search-result .ui-search-installments .ui-search-price__part {
    color: inherit;
    display: -webkit-flex;
    display: flex;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    margin: 0 .3rem;
}

.ui-search-layout--stack .ui-search-result .ui-search-installments .ui-search-price__part .price-tag-symbol {
    margin-right: .3rem;
}

.ui-search-layout--stack .ui-search-result .ui-search-installments-prefix .ui-search-price__part {
    margin-left: 0;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__group--shipping {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    display: block;
    margin-bottom: 12px;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__shipping {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-right: 10px;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__shipping--free {
    font-weight: 600;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__group--title {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-bottom: 10px;
}

.ui-search-layout--stack .ui-search-result .ui-search-item__title {
    color: #333333;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3;
}

.ui-search-layout--stack .ui-search-result .ui-search-result__bookmark {
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 16px;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
}

.ui-search-layout--stack .ui-search-result .ui-search-result__bookmark .ui-search-bookmark {
    opacity: 0;
}

.ui-search-layout--stack .ui-search-result .ui-search-bookmark {
    height: 36px;
    position: relative;
    width: 36px;
    z-index: 1;
}

.ui-search-layout--stack .ui-search-result .ui-search-bookmark__btn {
    -webkit-align-items: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    border: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #3483fa;
    content: "";
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    width: 100%;
}

.ui-search-layout--stack .ui-search-result .ui-search-bookmark__icon-bookmark, .ui-search-layout--stack .ui-search-result .ui-search-bookmark__icon-bookmark-fill {
    height: 18px;
    position: absolute;
    top: auto;
    width: 18px;
    z-index: 2;
}

.ui-search-layout--stack .ui-search-result .ui-search-bookmark__icon-bookmark {
    fill: transparent;
    stroke: currentColor;
    stroke-width: 2px;
}

.ui-search-layout--stack .ui-search-result .ui-search-bookmark__icon-bookmark-fill {
    fill: currentColor;
    stroke: transparent;
    opacity: 0;
}

.ui-search-layout--stack .ui-search-item__highlight-label {
    margin-bottom: 12px;
    margin-top: 4px;
}

.ui-search-layout--stack .ui-search-item__highlight-label--pricing_rebates {
    margin: 0;
}

.ui-search-layout--stack .ui-search-item__group--vertical-highlight .ui-search-item__group__element:last-child {
    margin-bottom: 0;
}

.ui-search-layout--stack .ui-search-item__group--title {
    -webkit-order: 0;
    order: 0;
}

.ui-search-layout--stack .ui-search-item__group--price {
    -webkit-order: 1;
    order: 1;
}

.ui-search-layout--stack .ui-search-result__content-column--left {
    -webkit-order: 2;
    order: 2;
}

.ui-search-layout--stack .ui-search-result__content-column--right {
    -webkit-order: 3;
    order: 3;
}

.ui-search-layout--stack .ui-search-layout__item:hover .ui-search-bookmark {
    opacity: 1;
    pointer-events: auto;
}

.ui-search-layout--stack .ui-search-bookmark__btn:hover {
    color: #1f4e96;
}

.ui-search-layout--stack .ui-search-result--core .ui-search-result__image .ui-search-result-image__element {
    -o-object-fit: contain;
    object-fit: contain;
}

.ui-search-layout--stack .ui-search-result--core .ui-search-item__group--price {
    margin-bottom: 12px;
}

.ui-search-layout--stack .ui-search-result--core .ui-search-item__group--price .ui-search-installments .ui-search-price {
    margin: 0;
}

.ui-search-layout--stack .ui-search-result--core .ui-search-item__group--price .ui-search-installments .ui-search-price__second-line {
    margin: 0 0 1px;
}

.ui-search-layout--stack .ui-search-result--core .ui-search-result__content-column--right {
    min-height: 16px;
    top: 7px;
    width: 285px;
}

@media (max-width: 1127px) {
    .ui-search-layout--stack .ui-search-result--core .ui-search-result__content-column--left {
        width: 100%;
    }

    .ui-search-layout--stack .ui-search-result--core .ui-search-result__content-column--right {
        bottom: 0;
        margin-bottom: 20px;
        top: 20px;
    }

}

.ui-search-layout--stack .ui-search-result--mot .ui-search-item__group--title {
    margin-bottom: 14px;
}

.ui-search-layout--stack .ui-search-result--mot .ui-search-item__group--title .ui-search-item__title {
    max-width: 100%;
}

.ui-search-layout--stack .ui-search-result--mot .ui-search-item__group--price {
    margin-bottom: 16px;
    margin-top: -4px;
}

.ui-search-layout--stack .ui-search-result--res .ui-search-item__group--price {
    margin-bottom: 9px;
}

.ui-search-layout--stack .ui-search-result--res .ui-search-item__group--title {
    margin-bottom: 38px;
    margin-top: 2px;
}

.ui-search-layout--stack .ui-search-result--res .ui-search-item__group--title .ui-search-item__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: unset;
}

.ui-search-layout--stack .ui-search-result--srv .ui-search-item__group--title {
    margin-bottom: 16px;
    margin-top: -2px;
}

.ui-search-layout--stack .ui-search-result--srv .ui-search-item__group--title .ui-search-item__title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 20px;
    font-weight: 500;
    height: 100%;
    line-height: 1.25;
    margin: 0;
    max-height: 52px;
}

.ui-search-layout--stack .ui-search-result--srv .ui-search-item__group--price {
    margin-bottom: 20px;
}

.ui-search-layout--stack .ui-search-result--srv .ui-search-item__group--price .ui-search-price__part.price-tag {
    margin-top: -5px;
}

.ui-search-layout--stack .ui-search-result--srv .ui-search-item__group--price .ui-search-price__part:not(.price-tag) {
    font-size: 18px;
    font-weight: 300;
}

.andes-form-control__search-trigger .andes-form-control__close-icon svg path, .andes-form-control__search-trigger .andes-form-control__search-icon svg path, .andes-form-control__search-trigger-visible svg path {
    fill: rgba(0, 0, 0, .55);
}

.andes-form-control--search-box label {
    -webkit-order: 2;
    order: 2;
    width: 100%;
}

.andes-form-control--search-box .andes-form-control__close-icon svg path, .andes-form-control--search-box .andes-form-control__search-icon svg path {
    fill: rgba(0, 0, 0, .55);
}

.faceted-search-desktop-dropdown button {
    font-size: 14px !important;
    margin: 0 !important;
}

.faceted-search-desktop-searchbox__icon > svg > path {
    fill: #3483fa;
}

.faceted-search-desktop-searchbox:focus-within .faceted-search-desktop-searchbox__icon > svg > path {
    fill: rgba(0, 0, 0, .9);
}

.faceted-search-highlighted-text b {
    font-weight: 700;
}

.ui-search-map-list__item .ui-search-result__wrapper {
    display: -webkit-flex;
    display: flex;
    padding-right: 20px;
}

.ui-search-map-list__item .ui-search-result__content .ui-search-price {
    color: rgba(0, 0, 0, .8);
}

.ui-search-map-list__item .ui-search-result__content .ui-search-price__part {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
}

.ui-search-map-list__item .ui-search-result__content .ui-search-price:after, .ui-search-map-list__item .ui-search-result__content .ui-search-price:before {
    margin: 0;
}

.ui-search-map-list__item .ui-search-result:hover:after, .ui-search-map-list__item .ui-search-result:hover:before {
    background: #3483fa;
    bottom: 4px;
    content: "";
    position: absolute;
    top: 4px;
    width: 2px;
}

.ui-search-map-list__item .ui-search-result:hover:before {
    -webkit-border-radius: 8px 0 0 8px;
    border-radius: 8px 0 0 8px;
    left: 2px;
}

.ui-search-map-list__item .ui-search-result:hover:after {
    -webkit-border-radius: 0 8px 8px 0;
    border-radius: 0 8px 8px 0;
    left: 4px;
}

.ui-search-map-list__item .ui-search-result:hover .ui-search-bookmark {
    opacity: 1;
    pointer-events: auto;
}

.ui-search-map-list__item .ui-search-result__bookmark {
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
}

.ui-search-map-list__item .ui-search-result__bookmark .ui-search-bookmark {
    opacity: 0;
}

.ui-search-map-list__item .ui-search-result__bookmark .ui-search-bookmark__icon-bookmark, .ui-search-map-list__item .ui-search-result__bookmark .ui-search-bookmark__icon-bookmark-fill {
    height: 16px;
    top: 12px;
}

.ui-search-map-list__item--development .ui-search-result:hover:after, .ui-search-map-list__item--development .ui-search-result:hover:before {
    background: #1f4e96;
}

.ui-search-map-list__item .ui-search-result {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ui-search-map-list .ui-search-pagination:last-child {
    margin: 0;
}

.ui-search-map-filters__primary-container--left > * ~ * {
    margin-left: 16px;
}

.ui-search-map-filters__filters-button .andes-button__content path {
    fill: #3483fa;
}

.ui-search-map-filters__filters-button:hover .andes-button__content path {
    stroke: #3483fa;
}

.ui-map-control-custom-controls > * ~ * {
    margin-left: 8px;
}

.ui-map-control-zoom-button > svg {
    min-height: 16px;
    min-width: 16px;
}

.ui-search-result__infowindow .ui-search-price__second-line {
    font-size: 18px;
    line-height: 15px;
}

.ui-search-bookmark {
    height: 36px;
    position: relative;
    width: 36px;
    z-index: 1;
}

.ui-search-bookmark__btn {
    -webkit-align-items: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    border: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #3483fa;
    content: "";
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    width: 100%;
}

.ui-search-bookmark__btn:hover {
    color: #1f4e96;
}

.ui-search-bookmark__icon-bookmark, .ui-search-bookmark__icon-bookmark-fill {
    height: 18px;
    width: 18px;
    z-index: 2;
}

.ui-search-bookmark__icon-bookmark {
    fill: transparent;
    stroke: currentColor;
    stroke-width: 2px;
}

.ui-search-bookmark__icon-bookmark-fill {
    fill: currentColor;
    stroke: transparent;
}

.ui-search-main--without-breadcrumb .ui-search-results {
    margin-top: 10px;
}

.ui-search-results {

    display: -webkit-flex;
    display: flex;


    -webkit-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
}

@media (max-width: 1164px)and (min-width: 1024px) {
    .ui-search-results {

    }
}

@media (max-width: 1023px)and (min-width: 862px) {
    .ui-search-results {

    }
}

@media (max-width: 861px) {
    .ui-search-results {

    }
}

.ui-search-layout {
    display: -webkit-inline-flex;
    display: inline-flex;
}

.ui-search-layout:last-child {
    margin-bottom: 0;
}

.col-3 {
    flex: 0 0 auto;
    width: 33%;

}

.col-2 {
    flex: 0 0 auto;
    width: 50%;

}

.ui-search-layout .ui-search-price__second-line .price-tag-cents {
    font-size: 12px;
    font-weight: 600;
}

.ui-search-layout .ui-search-price__second-line--decimal .price-tag-cents {
    font-size: inherit;
    font-weight: inherit;
}

.ui-search-layout .ui-search-price .price-tag__disabled .price-tag-cents {
    font-weight: 600;
    margin-bottom: 4px;
}

.ui-search-layout .ui-search-price .price-tag__disabled:after {
    top: 50%;
}

.ui-search-layout .ui-search-installments .ui-search-price__second-line .price-tag-cents {
    font-size: 10px;
}

.ui-search-item__title {
    -webkit-line-clamp: 2;
}

.ui-search-item__brand-discoverability, .ui-search-item__title {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.ui-search-item__brand-discoverability {
    -webkit-line-clamp: 1;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
}

.ui-search-item__brand-discoverability:after, .ui-search-item__brand-discoverability:before {
    content: "";
    display: block;
    height: 0;
    width: 0;
}

.ui-search-item__brand-discoverability:before {
    margin-top: -4px;
}

.ui-search-item__brand-discoverability:after {
    margin-bottom: 0;
}

@media (max-width: 1164px) {
    .ui-search-layout {
        -webkit-justify-content: center;
        justify-content: center;
    }
}

.ui-search-result .ui-search-result__highlight-container .ui-search-item__highlight-label {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.35;
    margin: 0;
    padding: 2px 6px;
}

.andes-modal a:focus-visible {
    -webkit-box-shadow: 0 0 2px 2px #3483fa;
    box-shadow: 0 0 2px 2px #3483fa;
    color: #3483fa;
}

@media (max-width: 860px)and (min-width: 767px) {

}

@media (min-width: 860px) {
}

.ui-search-pagination {
    color: rgba(0, 0, 0, .45);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

.ui-search-pagination:last-child {
    margin-bottom: 10px;
}

.ui-search-pagination .andes-pagination__button--current .andes-pagination__link {
    background-color: rgba(0, 0, 0, .04);
    color: rgba(0, 0, 0, .45);
    font-weight: 600;
}

.ui-search-pagination .andes-pagination__page-count {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin-left: 4px;
    white-space: nowrap;
}

.ui-search-pagination .andes-pagination__button {
    margin-left: 15px;
}

.ui-search-pagination .andes-pagination__arrow-title {
    color: #3483fa;
}

.ui-search-pagination .andes-pagination__arrow {
    border-color: #3483fa;
    height: 8px;
    margin-left: 2px;
    width: 8px;
}

.ui-search-search-modal__header > :not(:last-child) {
    margin-bottom: 24px;
}

.ui-search-bookmark {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
}

.ui-search-bookmark__btn {
    background-color: transparent;
    border: none;
    display: -webkit-inline-flex;
    display: inline-flex;
    height: 48px;
    padding: 16px;
    width: 49px;
}

.ui-search-bookmark__icon-bookmark, .ui-search-bookmark__icon-bookmark-fill {
    fill: none;
    stroke: #3483fa;
    height: 15px;
    overflow: visible;
    position: absolute;
    top: 16px;
}

.ui-search-bookmark__icon-bookmark g, .ui-search-bookmark__icon-bookmark-fill g {
    stroke-width: 1.5;
}

.ui-search-bookmark__icon-bookmark-fill {
    fill: #3483fa;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: opacity .25s ease-in-out, -webkit-transform .35s cubic-bezier(0, 0, .3, 1.5);
    transition: opacity .25s ease-in-out, -webkit-transform .35s cubic-bezier(0, 0, .3, 1.5);
    transition: opacity .25s ease-in-out, transform .35s cubic-bezier(0, 0, .3, 1.5);
    transition: opacity .25s ease-in-out, transform .35s cubic-bezier(0, 0, .3, 1.5), -webkit-transform .35s cubic-bezier(0, 0, .3, 1.5);
}

.ui-search-bookmark__cpg .ui-search-bookmark__btn {
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    height: 32px;
    padding: 8px;
    width: 32px;
}

.ui-search-bookmark__cpg .ui-search-bookmark__icon-bookmark, .ui-search-bookmark__cpg .ui-search-bookmark__icon-bookmark-fill {
    top: 8px;
}

.andes-card {
    background-color: #ffffff;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
}

.andes-card--flat {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .12);
}

.andes-card > :first-child {
    -webkit-border-top-left-radius: 6px;
    border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    border-top-right-radius: 6px;
}

.andes-card > :last-child {
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.andes-floating-menu li:first-child, .andes-floating-menu li:first-child a, .andes-floating-menu li:first-child button {
    -webkit-border-top-left-radius: .375em;
    border-top-left-radius: .375em;
    -webkit-border-top-right-radius: .375em;
    border-top-right-radius: .375em;
}

.andes-floating-menu li:last-child, .andes-floating-menu li:last-child a, .andes-floating-menu li:last-child button {
    -webkit-border-bottom-left-radius: .375em;
    border-bottom-left-radius: .375em;
    -webkit-border-bottom-right-radius: .375em;
    border-bottom-right-radius: .375em;
}

.andes-floating-menu .andes-list__item > :focus {
    box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 3px #2968c8, inset 0 0 0 5px rgba(65, 137, 230, .3);
    -moz-box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 3px #2968c8, inset 0 0 0 5px rgba(65, 137, 230, .3);
    -webkit-box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 3px #2968c8, inset 0 0 0 5px rgba(65, 137, 230, .3);
    outline: none;
}

.andes-floating-menu .andes-list__item > :focus-visible {
    box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 3px #2968c8, inset 0 0 0 5px rgba(65, 137, 230, .3);
    -moz-box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 3px #2968c8, inset 0 0 0 5px rgba(65, 137, 230, .3);
    -webkit-box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 3px #2968c8, inset 0 0 0 5px rgba(65, 137, 230, .3);
    outline: none;
}

.andes-floating-menu .andes-list__item > :focus:not(:focus-visible) {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
}

.andes-dropdown__chevron svg {
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.andes-dropdown__chevron path, .andes-dropdown__chevron svg {
    fill: #3483fa;
}

.andes-dropdown .andes-floating-menu .andes-dropdown__trigger span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.andes-dropdown .andes-floating-menu .andes-form-control__field:focus ~ .andes-dropdown__arrow-container path, .andes-dropdown .andes-floating-menu .andes-form-control__field:focus ~ .andes-dropdown__arrow-container svg {
    fill: #3483fa;
}

.andes-dropdown .andes-floating-menu .andes-dropdown__menu li:first-child {
    -webkit-border-top-left-radius: .375em;
    border-top-left-radius: .375em;
    -webkit-border-top-right-radius: .375em;
    border-top-right-radius: .375em;
}

.andes-dropdown .andes-floating-menu .andes-dropdown__menu li:last-child {
    -webkit-border-bottom-left-radius: .375em;
    border-bottom-left-radius: .375em;
    -webkit-border-bottom-right-radius: .375em;
    border-bottom-right-radius: .375em;
}

.andes-dropdown.andes-dropdown--form .andes-floating-menu .andes-dropdown__trigger span {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.andes-dropdown.andes-dropdown--form .andes-floating-menu .andes-form-control__field option {
    padding: 0;
}

.andes-dropdown--form.andes-form-control--error .andes-dropdown__chevron path, .andes-dropdown--form.andes-form-control--error .andes-dropdown__chevron svg {
    fill: #f23d4f;
}

.andes-tag__avatar-icon-container > * {
    height: 1em;
    width: 1em;
}

.andes-dropdown--form-native .andes-form-control__field option {
    padding: 0;
}


.andes-dropdown--form-native.andes-form-control--error .andes-form-control__field:focus ~ .andes-dropdown__arrow-container path, .andes-dropdown--form-native.andes-form-control--error .andes-form-control__field:focus ~ .andes-dropdown__arrow-container svg, .andes-dropdown--form-native.andes-form-control--error .andes-form-control__field ~ .andes-dropdown__arrow-container path, .andes-dropdown--form-native.andes-form-control--error .andes-form-control__field ~ .andes-dropdown__arrow-container svg {
    fill: #f23d4f;
}

.andes-list__item--size-small .andes-list__item-asset--icon > svg {
    margin-right: 8px;
    margin-top: .25em;
}

.andes-list__item--size-medium.andes-list__item-with-secondary .andes-list__item-asset > svg {
    padding-bottom: 1.4em;
}

.andes-list__item--size-medium .andes-list__item-asset > svg {
    margin-right: 12px;
    margin-top: .1em;
}

.andes-list__item--size-large.andes-list__item-with-secondary .andes-list__item-asset > svg {
    padding-bottom: 1.7em;
}

.andes-list__item--size-large .andes-list__item-asset > svg {
    margin-right: 16px;
    margin-top: .1em;
}

.andes-list--dropdown .andes-list__item-with-image .andes-list__item-first-column .andes-list__item-asset > svg, .andes-list--dropdown .andes-list__item-with-secondary .andes-list__item-first-column .andes-list__item-asset > svg {
    padding-bottom: 0;
}

@media (min-width: 768px) {
}

.andes-list__item--disabled .andes-list__item-first-column .andes-list__item-asset > svg path {
    fill: rgba(0, 0, 0, .25);
}

.andes-list__item--disabled.andes-list__item--selected .andes-list__item-first-column .andes-list__item-asset > svg path {
    fill: rgba(0, 0, 0, .25);
}

.andes-dropdown--compact button {
    -webkit-order: unset;
    order: unset;
}

.andes-list a, .andes-list li {
    display: block;
    position: relative;
}

.andes-list a:after, .andes-list li:after {
    background: #9cc4db;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 0;
}

.andes-list a:hover:after, .andes-list li:hover:after {
    width: 5px;
}

.ui-search-variations-picker .andes-card__content .andes-list li:after {
    background: unset !important;
}

.ui-search-item__group--pds .ui-search-price__second-line {
    margin-top: 0 !important;
}

.ui-search-item__highlight-label {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: table-cell;
    font-weight: 600;
    padding: 2px 4px;

    width: -moz-fit-content;
    width: fit-content;
}

.ui-search-item__highlight-label--no-background {
    font-weight: 400;
    padding: 0;
}

.ui-search-item__highlight-label__container {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    gap: 4px;
}

.ui-search-item__highlight-label--media .ui-search-item__highlight-label__container {
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

.ui-search-item__highlight-label--media .ui-search-item__highlight-label__container > svg {
    height: 11px;
}

.ui-search-item__highlight-label--media .ui-search-item__highlight-label__container .ui-search-item__highlight-label__text {
    color: #3483fa;
    font-size: 10px;
    margin-left: 3px;
}

.ui-search-item__highlight-label--pricing_rebates .ui-search-item__highlight-label__text {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    line-height: 20px;
}

.ui-search-result .ui-search-item__highlight-group .ui-search-item__highlight-label {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    font-size: 12px;
    margin: 0 0 4px;
    padding: 0 4px 2px;
}

.ui-search-item__highlight-label {
    font-size: 12px;
}

.andes-tooltip--highlight .andes-tooltip__actions a:first-child, .andes-tooltip--highlight .andes-tooltip__actions button:first-child {
    background-color: #2968c8;
}

.andes-tooltip__actions a, .andes-tooltip__actions button {
    margin-left: 4px;
}

.andes-tooltip__actions a:first-child, .andes-tooltip__actions button:first-child {
    margin-left: 0;
}

.andes-thumbnail > img {
    height: auto;
    max-width: 100%;
    width: auto;
}

.andes-radio-inverted span {
    padding-right: 14px;
}

@keyframes rotate-left {
    to {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
    0% {
        -webkit-transform: rotate(175deg);
        transform: rotate(175deg);
    }
}

@keyframes rotate-right {
    0% {
        -webkit-transform: rotate(-175deg);
        transform: rotate(-175deg);
    }
    to {
        -webkit-transform: rotate(-30deg);
        transform: rotate(-30deg);
    }
}

@keyframes rotate-all {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.tooltip-periodicity h3 {
    font-size: 16px;
    font-weight: 700;
}

.tooltip-periodicity label {
    font-size: 16px;
}

.ui-search-alert__btn-alert p {
    color: #3483fa;
    cursor: pointer;
    margin-left: 2px;
}

.ui-search-alert-edition-tooltip p {
    margin-bottom: 5px;
}

.andes-carousel-snapped__slide img {
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    width: 100%;
}

.andes-carousel-snapped__control svg, .andes-carousel-snapped__control svg path {
    fill: #3483fa;
}

.andes-carousel-snapped__pagination-item button {
    border: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    height: 6px;
    margin: 0 2px;
    padding: 0;
    -webkit-transition: background-color .35s ease-in-out, -webkit-box-shadow .35s ease-in-out;
    transition: background-color .35s ease-in-out, -webkit-box-shadow .35s ease-in-out;
    transition: background-color .35s ease-in-out, box-shadow .35s ease-in-out;
    transition: background-color .35s ease-in-out, box-shadow .35s ease-in-out, -webkit-box-shadow .35s ease-in-out;
    width: 6px;
}

.andes-carousel-snapped__pagination--light .andes-carousel-snapped__pagination-item button {
    background-color: rgba(0, 0, 0, .1);
}

.andes-carousel-snapped__pagination--light .andes-carousel-snapped__pagination-item--active button {
    background-color: #3483fa;
}

.andes-carousel-snapped__pagination--dark .andes-carousel-snapped__pagination-item button {
    background-color: rgba(0, 0, 0, .25);
    -webkit-box-shadow: inset 0 0 0 1px #ffffff;
    box-shadow: inset 0 0 0 1px #ffffff;
}

.andes-carousel-snapped__pagination--dark .andes-carousel-snapped__pagination-item--active button {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .25);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .25);
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px) {
}

@media (max-width: 1164px)and (min-width: 1024px) {

}

@media (max-width: 1023px) {

}

.ui-search-carousel--official_stores .slick-list {
    padding-left: 8px;
}

.ui-search-carousel--official_stores .slick-track {
    display: -webkit-flex;
    display: flex;
    max-height: 90px;
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px)and (min-width: 862px) {
}

@media (max-width: 861px) {
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px)and (min-width: 862px) {
}

@media (max-width: 861px) {
}

.ui-search-carousel--brands .slick-slide {
    margin-top: -3px;
    min-height: 104px;
}

.ui-search-carousel--brands .slick-track {
    display: -webkit-flex;
    display: flex;
    max-height: 120px;
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px)and (min-width: 862px) {
}

@media (max-width: 861px) {
}

.ui-search-carousel--cpg .slick-slide {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    height: 254px;
    margin: 0 8px 50px;
    max-width: 164px;
    overflow: visible;
    z-index: 1;
}

@media (max-width: 1164px)and (min-width: 1024px) {
    .ui-search-carousel--cpg .slick-slide {
        height: 261px;
        margin-left: 6px;
        max-width: 175px;
    }
}

@media (max-width: 1023px)and (min-width: 862px) {
    .ui-search-carousel--cpg .slick-slide {
        height: 269px;
        margin-left: 6px;
        max-width: 184px;
    }
}

@media (max-width: 861px) {
    .ui-search-carousel--cpg .slick-slide {
        margin-left: 4px;
        max-width: 155px;
    }
}

.ui-search-carousel--cpg .slick-slide li {
    display: inline-block;
    height: 100%;
}

.ui-search-carousel--cpg .slick-slide:first-child {
    z-index: 2;
}

.ui-search-carousel--cpg .slick-list {
    max-height: 299px;
    overflow: hidden;
}

.ui-search-carousel--cpg .slick-track {
    padding: 8px 0 32px;
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px)and (min-width: 862px) {
}

@media (max-width: 861px) {
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px)and (min-width: 862px) {
}

@media (max-width: 861px) {
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .ui-search-price__part.price-tag.price-tag__disabled {
    margin-bottom: 0;
    position: absolute;
    top: 3px;
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .ui-search-price__discount {
    color: #00a650;
    font-size: 12px;
    line-height: 1.17;
    margin-left: 5px;
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .ui-search-price__second-line .ui-search-price__part {
    color: rgba(0, 0, 0, .9);
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .ui-search-price__second-line .price-tag-symbol {
    margin-right: 6px;
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .ui-search-price__second-line .ui-search-price__discount {
    font-size: 12px;
    margin-bottom: 3px;
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .ui-search-price__original-value {
    color: #999999;
    font-weight: 400;
    margin-bottom: 5px;
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .ui-search-price__original-value .price-tag-symbol {
    margin-right: 3px;
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic-card__price .price-tag-cents {
    font-weight: 600;
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px)and (min-width: 862px) {
}

@media (max-width: 861px) {
}

@media (max-width: 1164px)and (min-width: 1024px) {
}

@media (max-width: 1023px)and (min-width: 862px) {
}

@media (max-width: 861px) {
}

.ui-search-carousel--cpg .ui-search-carousel__item-generic__view-more__container .ui-search-icon--cpg-more g {
    fill: #3483fa;
}

@media (max-width: 720px) {
}

.ui-search-carousel--billboard .andes-carousel-snapped__control__icon-container svg {
    -webkit-filter: drop-shadow(0 0 1px #000000);
    filter: drop-shadow(0 0 1px #000000);
    height: 50px;
    width: 50px;
}

.ui-search-carousel--billboard .andes-carousel-snapped__control__icon-container svg path {
    fill: #ffffff;
}

.ui-search-carousel--billboard .ui-search-bookmark {
    background: rgba(255, 255, 255, 0.7);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    opacity: 0;
    overflow: hidden;
    right: 16px;
    top: 16px;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
}

.ui-search-carousel--billboard .ui-search-bookmark, .ui-search-carousel--billboard .ui-search-bookmark__btn {
    height: 36px;
    pointer-events: none;
    width: 36px;
}

.ui-search-carousel--billboard .ui-search-bookmark__btn:hover .ui-search-bookmark__icon-bookmark {
    stroke: #1259c3;
}

.ui-search-carousel--billboard .ui-search-bookmark__icon-bookmark {
    height: 18px;
    left: 25%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 18px;
}

.ui-search-carousel--billboard .ui-search-bookmark__icon-bookmark-fill {
    left: 27%;
    top: 29%;
}

.ui-search-carousel--billboard:hover .ui-search-bookmark, .ui-search-carousel--billboard:hover .ui-search-bookmark__btn {
    cursor: pointer;
    display: block;
    opacity: 1;
    pointer-events: all;
}

.ui-search-carousel--billboard .ui-search-billboard__content .ui-search-price__second-line {
    display: -webkit-flex;
    display: flex;
}

.ui-search-sidebar-advertising a:hover {
    text-decoration: none;
}

.ui-search-sidebar-advertising .url {
    margin-bottom: 3px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-search-sidebar-advertising .adHere a {
    color: #3483fa;
}

.ui-search-sidebar-advertising .adHere a:hover {
    color: #1259c3;
}

.ui-search-listing-disclaimer--stack + .ui-search-pagination .ui-search-pagination.andes-pagination {
    margin-top: 0;
}

*, :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background: #ededed;
}

main[role=main] {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, .1);
    font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.35;
}

::-moz-selection {
    background: rgba(0, 0, 0, .1);
}

::selection {
    background: rgba(0, 0, 0, .1);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
}

p {
    margin: 0;
}

a, a:hover, a:link, a:visited {
    text-decoration: none;
}

a {
    color: #3483fa;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
}

a:focus, a:hover {
    color: #2968c8;
}

a:hover {
    cursor: pointer;
}

a:active {
    color: #1f4e96;
}

ol, ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

button, textarea {
    font-family: inherit;
}

.ui-search-vehicle-body-selector ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 8px;
    width: 100%;
}

@media (max-width: 773px) {
}

@media (max-width: 773px) {
}

@media (max-width: 773px) {
}

.ui-search-bottom-ads__container *, .ui-search-bottom-ads__container :after, .ui-search-bottom-ads__container :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ui-search-bottom-ads__container body {
    background: #ededed;
}

.ui-search-bottom-ads__container main[role=main] {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, .1);
    font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.35;
}

.ui-search-bottom-ads__container ::-moz-selection {
    background: rgba(0, 0, 0, .1);
}

.ui-search-bottom-ads__container ::selection {
    background: rgba(0, 0, 0, .1);
}

.ui-search-bottom-ads__container h1, .ui-search-bottom-ads__container h2, .ui-search-bottom-ads__container h3, .ui-search-bottom-ads__container h4, .ui-search-bottom-ads__container h5, .ui-search-bottom-ads__container h6 {
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
}

.ui-search-bottom-ads__container p {
    margin: 0;
}

.ui-search-bottom-ads__container a, .ui-search-bottom-ads__container a:hover, .ui-search-bottom-ads__container a:link, .ui-search-bottom-ads__container a:visited {
    text-decoration: none;
}

.ui-search-bottom-ads__container a {
    color: #3483fa;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
}

.ui-search-bottom-ads__container a:focus {
    color: #2968c8;
}

.ui-search-bottom-ads__container a:hover {
    color: #2968c8;
    cursor: pointer;
}

.ui-search-bottom-ads__container a:active {
    color: #1f4e96;
}

.ui-search-bottom-ads__container ol, .ui-search-bottom-ads__container ul {
    margin: 0;
    padding: 0;
}

.ui-search-bottom-ads__container li {
    list-style: none;
}

.ui-search-bottom-ads__container button, .ui-search-bottom-ads__container textarea {
    font-family: inherit;
}

.ui-search-bottom-ads__container .ui-recommendations-list a:active, .ui-search-bottom-ads__container .ui-recommendations-list a:link, .ui-search-bottom-ads__container .ui-recommendations-list a:visited {
    text-decoration: none;
}

@media (min-width: 1248px) {
}

.ui-search-bottom-ads__container .ui-recommendations-card__melicoin-text svg {
    height: 15px;
    width: 15px;
}

@media (min-width: 1248px) {
}

@media (min-width: 1024px) {
}

@media (max-width: 1164px) {

}

.ui-search-zrp-disclaimer a, .ui-search-zrp-disclaimer a:hover, .ui-search-zrp-disclaimer a:link, .ui-search-zrp-disclaimer a:visited {
    text-decoration: underline;
}

.ui-search-info-text-box__details--is-collapsed > :first-child, .ui-search-info-text-box__details--is-collapsed > :nth-child(2) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.ui-search-info-text-box__details--is-collapsed > :first-child {
    -webkit-line-clamp: 2;
}

.ui-search-info-text-box__details--is-collapsed > :nth-child(2) {
    -webkit-line-clamp: 1;
}

@-webkit-keyframes search-fullinfo-progress {
    0% {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes search-fullinfo-progress {
    0% {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@-webkit-keyframes search-fullpopup-bottom-show {
    0% {
        opacity: 0;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }
}

@keyframes search-fullpopup-bottom-show {
    0% {
        opacity: 0;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }
}

@-webkit-keyframes search-fullpopup-bottom-hide {
    0% {
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0);
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -200%, 0);
        transform: translate3d(0, -200%, 0);
    }
}

@keyframes search-fullpopup-bottom-hide {
    0% {
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0);
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -200%, 0);
        transform: translate3d(0, -200%, 0);
    }
}

@-webkit-keyframes search-fullpopup-top-show {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -200px, 0);
        transform: translate3d(0, -200px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes search-fullpopup-top-show {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -200px, 0);
        transform: translate3d(0, -200px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-webkit-keyframes search-fullpopup-top-hide {
    0% {
        -webkit-transform: translate3d(0, -85%, 0);
        transform: translate3d(0, -85%, 0);
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -200%, 0);
        transform: translate3d(0, -200%, 0);
    }
}

@keyframes search-fullpopup-top-hide {
    0% {
        -webkit-transform: translate3d(0, -85%, 0);
        transform: translate3d(0, -85%, 0);
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -200%, 0);
        transform: translate3d(0, -200%, 0);
    }
}

.ui-search-rebates {
    margin-bottom: 12px;
}

.ui-search-rebates-rebate {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 4px;
}

.ui-search-rebates-rebate__pill {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 2px;
    margin-right: 6px;
}

.ui-search-rebates .ui-search-item__highlight-label__container {
    display: block;
}

.ui-search-rebates .ui-search-item__highlight-label__container .ui-search-item__highlight-label__text {
    padding: 1px 4px;
}

.andes-money-amount .andes-money-amount__logo-12 svg {
    height: 9px;
    width: 9px;
}

.andes-money-amount .andes-money-amount__logo-14 svg {
    height: 11px;
    width: 11px;
}

.andes-money-amount .andes-money-amount__logo-16 svg {
    height: 13px;
    width: 13px;
}

.andes-money-amount .andes-money-amount__logo-18 svg {
    height: 15px;
    width: 15px;
}

.andes-money-amount .andes-money-amount__logo-20 svg {
    height: 17px;
    width: 17px;
}

.andes-money-amount .andes-money-amount__logo-24 svg {
    height: 20px;
    width: 20px;
}

.andes-money-amount .andes-money-amount__logo-28 svg {
    height: 22px;
    width: 22px;
}

.andes-money-amount .andes-money-amount__logo-32 svg {
    height: 24px;
    width: 24px;
}

.andes-money-amount .andes-money-amount__logo-36 svg {
    height: 27px;
    width: 27px;
}

.andes-money-amount .andes-money-amount__logo-40 svg {
    height: 28px;
    width: 28px;
}

.andes-money-amount .andes-money-amount__logo-44 svg {
    height: 32px;
    width: 32px;
}

.andes-money-amount .andes-money-amount__logo-48 svg {
    height: 34px;
    width: 34px;
}

.andes-money-amount .andes-money-amount__logo-52 svg {
    height: 38px;
    width: 38px;
}

.andes-money-amount .andes-money-amount__logo-56 svg {
    height: 41px;
    width: 41px;
}

.andes-money-amount .andes-money-amount__logo-60 svg {
    height: 43px;
    width: 43px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-12 svg {
    height: 16px;
    width: 16px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-14 svg {
    height: 16px;
    width: 16px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-16 svg {
    height: 16px;
    width: 16px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-18 svg {
    height: 20px;
    width: 20px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-20 svg {
    height: 20px;
    width: 20px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-24 svg {
    height: 24px;
    width: 24px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-28 svg {
    height: 28px;
    width: 28px;
}

.andes-money-amount__discount .andes-money-amount__discount-icon-32 svg {
    height: 32px;
    width: 32px;
}

.label-padding-mobile svg {
    height: 11px;
    width: 13px;
}

.label-padding-desktop svg {
    height: 13px;
    width: 16px;
}

.ui-search-installments-prefix .ui-search-price__part {
    margin-left: 0;
}

.ui-search-value-proposition__pills .ui-search-item__highlight-label {
    font-size: 14px;
}

@media (max-width: 445px) {
}

.ui-search-discount-message .andes-thumbnail-container path {
    fill: #26a65b;
}

@media (min-width: 768px) {
}

.ui-search-price-details .ui-search-price--size-tiny .ui-search-price__part.price-tag {
    color: inherit;
    font-weight: 300;
}

.ui-search-price-details .ui-search-price--size-tiny .ui-search-price__part.price-tag .price-tag-symbol {
    margin-right: .2em;
}

.ui-search-price-details .ui-search-price--size-tiny .ui-search-price__part.price-tag .price-tag-cents {
    font-weight: 300;
    line-height: 1;
    padding-top: 0;
}

.ui-search-price-details .ui-search-price--size-tiny .ui-search-price__part.price-tag {
    font-size: 14px;
    margin-right: 4px;
}

.ui-search-price-details .ui-search-price--size-tiny .ui-search-price__part.price-tag .price-tag-cents {
    font-size: 10px;
}

@media (max-width: 768px) {
}

.ui-search-filter-intervention .andes-thumbnail img {
    height: auto;
    width: 62px;
}

.hero__left img {
    -o-object-fit: contain;
    object-fit: contain;
}


.grid .ui-recommendations-card__melicoin-text svg {
    height: 15px;
    width: 15px;
}


@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) {
}

.ui-search-carousel-card-main__link-image img {
    height: 132px !important;
    margin: 36px 24px 0 0;
    width: 108px;
}

main[role=main] {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

.andes-dropdown__trigger .andes-dropdown__standalone-arrow path {
    fill-opacity: 1;
}

/*# sourceMappingURL=search.desktop.6289a738.css.map*/

/* ==========================================================================
   #SELECT2
   ========================================================================== */
.rs-select2 .select2-container .select2-selection--single .select2-selection__arrow b {
    display: none;
}


old-price {

    color: rgba(0, 0, 0, .55);
    font-size: 8px;
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}

.row-space {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width: 767px) {
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */

h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
    margin: 0;
    padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
    margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
    min-width: 0;
    /* [1] */
    border: 0;
}

button {
    outline: none;
    background: none;
    border: none;
    font-family: inherit;
}

/* ==========================================================================
   #PAGE WRAPPER
   ========================================================================== */
.page-wrapper {
    min-height: 40vh;
    margin-top: 0;
    margin-bottom: 25px;
}


body {
    font-family: "Lato", "Arial", "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 15px;
}

h6 {
    font-size: 13px;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    display: inline-block;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

/* ==========================================================================
   #BACKGROUND
   ========================================================================== */
/* ==========================================================================
   #SPACING
   ========================================================================== */
.p-t-15 {
    padding-top: 15px;
}

.p-t-5 {
    padding-top: 5px;
}

@media (max-width: 767px) {
}

@media (max-width: 767px) {
}

@media (max-width: 767px) {
}

.m-r-45 {
    margin-right: 45px;
}

@media (max-width: 767px) {
    .m-r-45 {
        margin-right: 15px;
    }
}

.m-t-0 {
    margin-top: 0;
}


/* ==========================================================================
   #WRAPPER
   ========================================================================== */
.wrapper {
    margin: 0 auto;
}

.wrapper--w1000 {
    max-width: 1000px;
}

/* ==========================================================================
   #BUTTON
   ========================================================================== */
.btn-submit {
    display: block;
    margin-top: 14px;
    width: 100%;
    line-height: 50px;
    font-family: inherit;
    background: #4e91fc;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 8px;
}

.btn-submit:hover {
    background: #366ebe;
}

.m-t-0 {
    margin-top: 0;
}

/* ==========================================================================
   #DATEPICKER
   ========================================================================== */


.daterangepicker .calendar th {
    font-size: 14px;
    color: #aaaaaa;
    cursor: default;
}

@media (max-width: 767px) {

}

.daterangepicker .calendar-table .table-condensed thead tr:nth-child(2) th {
    padding-top: 27px;
    padding-bottom: 17px;
}

.daterangepicker td {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-family: "Lato", "Arial", "Helvetica Neue", sans-serif;
    text-align: center;
    font-size: 14px;
    color: #555555;
}

.daterangepicker td.in-range {
    background: #ffe1c4;
}


.daterangepicker td.active,
.daterangepicker td.active:hover {
    background: #f5f5f5;
}

/* ==========================================================================
#FORM
========================================================================== */
input,
textarea {
    outline: none;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-size: 18px;
    font-family: inherit;
}

input:disabled {
    background: transparent;
    cursor: pointer;
}

textarea {
    resize: none;
}

button {
    cursor: pointer;
}

.input-group {
    position: relative;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 34px;
}

.input-group-search {
    position: relative;
    margin-bottom: 34px;
}

.select-group {

    position: relative;
    margin-bottom: 34px;
    text-align: left;

}

.label {
    font-size: 12px;
    color: #595959;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
}

.input--style-1 {
    font-weight: 700;
    font-size: 18px;
    padding: 11px 0;
    color: #555555;
}

.input--style-1::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #9f9b9b;
}

.input--style-1:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #9f9b9b;
    opacity: 1;
}

.input--style-1::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #9f9b9b;
    opacity: 1;
}

.input--style-1:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9f9b9b;
}

.input--style-1:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #9f9b9b;
}


/* CHECKBOX */
.checkbox-container {
    display: inline-block;
    position: relative;
    padding-right: 10px;
    cursor: pointer;
    font-size: 15px;
    color: #595959;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #cccccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: transparent;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 7px;
    height: 10px;
    border: solid #00ad5f;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ==========================================================================
   #SELECT
   ========================================================================== */
.quantity {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    float: right;
}

@media (max-width: 575px) {
    .quantity {
        float: none;
        margin-top: 10px;
    }
}

.quantity > input {
    -webkit-appearance: none;
    width: 55px;
    text-align: center;
    font-size: 18px;
    color: #555555;
    font-weight: 700;
}

.minus,
.plus {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 26px;
    text-align: center;
    border: 2px solid #cccccc;
    font-size: 24px;
    color: #cccccc;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.minus:hover,
.plus:hover {
    background: #cccccc;
    color: #ffffff;
}

.list-room__item {
    margin-bottom: 20px;
}

.list-room__name {
    margin-bottom: 27px;
    display: block;
    font-weight: 700;
    color: #999999;
}

.list-person {
    list-style: none;
}

.list-person .list-person__item:last-child {
    margin-bottom: 0;
}

.list-person__item {
    margin-bottom: 34px;
}

.list-person__item::after {
    content: "";
    clear: both;
    display: table;
}

.list-person__item .name {
    font-size: 18px;
    color: #555555;
    font-weight: 700;
    display: inline-block;
    margin-top: 5px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* ==========================================================================
#SELECT2
========================================================================== */
.rs-select2 .select2-container .select2-selection--single .select2-selection__arrow b {
    display: none;
}

/* ==========================================================================
#TAB
========================================================================== */
.tab-list {
    list-style: none;
    background: #00803B;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    border-top-left-radius: 10px;
    padding: 0 20px;
}

.tab-list::after {
    content: "";
    clear: both;
    display: table;
}

@media (max-width: 576px) {
    .tab-list {
        padding: 10px 20px;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topright: 0;
        border-top-right-radius: 0;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius-topleft: 0;
        border-top-left-radius: 0;
    }
}

.tab-list__item {
    float: left;
}

.tab-list__item.active {
    position: relative;
}

.tab-list__item.active::after {
    top: 100%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 8px solid rgba(250, 66, 81, 0);
    border-top-color: #00a650;
    margin-left: -8px;
}

@media (max-width: 576px) {
    .tab-list__item {
        float: none;
    }

    .tab-list__item.active::after {
        display: none;
    }
}

@font-face {
    font-family: 'bebas';
    src: url('~@/assets/fonts/FjallaOne-Regular.ttf') format('truetype');
}

.tab-list__link {
    display: block;
    text-transform: uppercase;
    font-family: "Bebas", "Open Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: #fafafa;
    padding: 14px 0 10px 140px;
}

.tab-list__link:hover {
    color: #fafafa;
}

@media (max-width: 767px) {
    .tab-list__link {
        padding: 3px 10px;
    }
}

.tab-list .active .tab-list__link {
    color: #fafafa;
}


.tab-content > .tab-pane {
    display: none;
    padding: 20px 20px 20px;
}

.tab-content > .tab-pane.active {
    display: block;
}

@media (max-width: 767px) {
    .tab-content > .tab-pane {
        padding: 20px;
    }
}

/* ==========================================================================
   #TITLE
   ========================================================================== */
/* ==========================================================================
   #CARD
   ========================================================================== */
.card-1 {
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
    overflow-x: hidden;
}

@media (min-width: 768px) {
    .card-1 {
        margin-top: 10px; /* Ajusta el valor según tus necesidades */
    }
}

#listado-productos {

    margin-top: 20px;
    border-radius: 10px;
}

.card-1 .card1-body {
    background: #ffffff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.container-top {
    display: flex;
    align-items: center;
}

.element-top {
    display: flex;
    align-items: center;
}

</style>
